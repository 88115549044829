import { generateChildrenFromList } from 'cms/parsers';
import { Box, TwoColumn } from 'dma-ui-kit';
import { colorMap } from 'helpers/colormap';
import styled from 'styled-components';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const TwoColumnParagraph = (props) => {
    const { attributes, relationships } = props.paragraph;

    const field_reverse = attributes.field_reverse ? attributes.field_reverse : false;
    const children_left = generateChildrenFromList(relationships.field_column_one_content.data);
    const children_right = generateChildrenFromList(relationships.field_column_two_content.data);
    const variant = (relationships.field_two_column_layout.data?.attributes?.name) ? relationships.field_two_column_layout.data.attributes.name : '';

    // Maps Taxonomy Term to Typography class
    const backgroundColor = (relationships.field_background_color?.data?.attributes?.name) ? 
        (colorMap[relationships.field_background_color.data.attributes.name]) ? 
            colorMap[relationships.field_background_color.data.attributes.name] : 
            '' : 
        '';

    return (
        <Container>
            <TwoColumn variant={variant} reverse={field_reverse} backgroundColor={backgroundColor}>
                <div>{children_left}</div>
                <div>{children_right}</div>
            </TwoColumn>
        </Container>
    );
};

export default TwoColumnParagraph;