
export const handleCTA = (link, history, window) => {
    if (link.includes('mailto')) {
        window.location = link;
    } else if (link.startsWith('#')) {
        window.location.hash = link.replace('#', '');
    } else if (link.includes('http')) {
        window.open(link, '_blank');
    } else {
        history.push(link.replace('internal:', '')); 
    }

    return;
};
