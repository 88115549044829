import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import SiteContext from 'site/SiteContext';

import { SearchableSection } from 'search/SearchableSection';
import { buildQuery, reducer as queryReducer } from 'search/queries';
import { buildIIIFImageURL } from 'helpers/collection';

import { Box, FlexBox, Image } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import ImageNotFound from 'assets/resultmissing.png';

import styled from 'styled-components';

const NoLink = styled(Box)`
    padding: 0 10px;
`;

const DisplayImage = styled(FlexBox)`
    height: 300px;
    min-width: 300px;
    margin: auto;
    
`;

const AdoptableArtwork = React.memo((props) => {

  const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
  const envir = process.env.REACT_APP_COLLECTION_ENV;
  const { searchPath } = useContext(SiteContext);
  const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_collection_objects/_search`;

   const [query, setQuery] = useState(null);

    const { attributes } = props.paragraph;
    const { field_tms_object_id } = attributes;

    const [options, dispatch] = useReducer(
      queryReducer,
      {  
        type: 'object',
        page: 1,
        term: field_tms_object_id,
        limit: 1,
        filters: {}
      },  
    );

    const labels = {
      noObjectsFound: translateInterfaceText("No objects found")
    };

    useEffect(() => {
      const nextQuery = buildQuery(options);
      setQuery(nextQuery);
    }, [buildQuery, setQuery, options]);

    return (
      <Box w="80%" margin="auto" center="center">
        <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/art/collection/search"}>
          {({ results, loading }) => {
            return (
              <>
                {(results?.hits?.length > 0) ? 
                  results.hits.map((result, index) => {
                    const imgSrc = (result.primary_image == -1) ? ImageNotFound : buildIIIFImageURL(result.id, result.primary_image, {size: 300} );
                    const constituent = result.constituents[0] ? `${result.constituents[0].name}` : ''; 
                    return (
                        <Link key={index} to={{ pathname: `/art/collection/object/${result.id}`}}>
                            <DisplayImage>
                                <Image src={imgSrc} alt={result.title} />
                            </DisplayImage>
                        </Link>                        
                    );
                  }) : (
                    <NoLink>
                        <Image src={ImageNotFound} alt={labels.noObjectsFound} />
                    </NoLink>
                  )}
              </>
            );
          }}
        </SearchableSection>
      </Box>
    );
});

export default AdoptableArtwork;